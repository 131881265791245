import { AllCountries } from '@/constants/Countries';

export function CountryNameConverter(countryCode: string) {
  const country = AllCountries.find((c) => c.abbr === countryCode);
  return country?.name || countryCode;
}

export function CountryNameToCountryCodeConverter(countryName: string) {
  const country = AllCountries.find((c) => c.name === countryName);
  return country?.abbr || countryName;
}

export const ReleaseCategoryNameFormatter = (category: number) => {
  if (category !== undefined) {
    switch (category) {
      case 0:
        return 'release';
      case 1:
        return 'marketing';
      case 2:
        return 'distribution';
      case 3:
        return 'socials';
      default:
        return 'default';
    }
  }
  return 'default';
};

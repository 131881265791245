export const PromoteFlowStepFormatter = (product: string, step: number, isShort?: boolean) => {
  if (product === 'playlisting') {
    switch (step) {
      case 1:
        return 'Choose Your Songs';
      case 2:
        return 'Recommended Playlists';
      case 3:
        return 'Summary';
      case 4:
        return 'Payment';
      default:
        return step.toString();
    }
  }
  if (product === 'fan-hub') {
    switch (step) {
      case 0:
        return 'Walkthrough';
      case 1:
        return 'Landing';
      case 2:
        return 'Community';
      case 3:
        return 'Spotlight Release';
      case 4:
        return 'YouTube Video';
      case 5:
        return 'Platform Links';
      case 6:
        return 'Tracking Pixels';
      case 7:
        return 'Choose URL path';
      case 8:
        return 'Background Picker';
      case 9:
        return 'Website Preview';
      case 10:
        return 'Summary';
      case 11:
        return 'View Website';
      default:
        return step.toString();
    }
  }
  if (product === 'meta' || product === 'tiktok') {
    switch (step) {
      case 1:
        return isShort ? 'Platform' : 'Goal';
      case 2:
        return isShort ? 'Budget' : 'Platform';
      case 3:
        return isShort ? 'Audience' : 'Budget';
      case 4:
        return isShort ? 'Design your ad' : 'Audience';
      case 5:
        return isShort ? 'Summary' : 'Design your ad';
      case 6:
        return 'Summary';
      default:
        return step.toString();
    }
  }
  if (product === 'onboarding') {
    switch (step) {
      case 0:
        return 'Pre-Screen';
      case 1:
        return 'User Details';
      case 2:
        return 'Spotify Artist Details';
      case 3:
        return 'Start Type Choice';
      case 4:
        return 'Release Name';
      case 5:
        return 'Release Date';
      case 6:
        return 'Login';
      case 7:
        return 'Sign Up';
      case 8:
        return 'Create Account';
      default:
        return step.toString();
    }
  }
  if (product === 'release-cycle') {
    switch (step) {
      case 4:
        return 'Release Name';
      case 5:
        return 'Release Date';
      case 9:
        return 'Release Type Choice';
      case 10:
        return 'Boost Release Track Choice';
      default:
        return step.toString();
    }
  }
  if (product === 'promote-choice') {
    switch (step) {
      case 0:
        return 'Boost Release';
      case 1:
        return 'New Release';
      case 2:
        return 'No Release';
      default:
        return step.toString();
    }
  }
  if (product === 'lyra-basic-info') {
    switch (step) {
      case 0:
        return 'Explainer';
      case 1:
        return 'Your Story';
      case 2:
        return 'Your Career';
      case 3:
        return 'Your Socials';
      default:
        return step.toString();
    }
  }
  if (product === 'lyra-edit') {
    switch (step) {
      case 0:
        return 'Edit Generation';
      default:
        return step.toString();
    }
  }
  if (product === 'lyra-full') {
    switch (step) {
      case 0:
        return 'Explainer';
      case 1:
        return 'Your Story';
      case 2:
        return 'Your Career';
      case 3:
        return 'Your Socials';
      case 4:
        return 'Release Details';
      case 5:
        return 'Edit Generation';
      default:
        return step.toString();
    }
  }
  if (product === 'lyra-short') {
    switch (step) {
      case 0:
        return 'Explainer';

      case 1:
        return 'Release Details';
      case 2:
        return 'Your Career';
      case 3:
        return 'Edit Generation';
      default:
        return step.toString();
    }
  }
  return '';
};

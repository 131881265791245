export const platformNameConverter = (platform: string) => {
  switch (platform) {
    case 'spotify':
      return 'Spotify';
    case 'youtube':
      return 'YouTube';
    case 'youtube_channel':
      return 'YouTube';
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    case 'facebook':
      return 'Facebook';
    case 'deezer':
      return 'Deezer';
    case 'twitter':
      return 'X';
    case 'soundcloud':
      return 'SoundCloud';
    case 'unhurd':
      return 'un:hurd music';
    default:
      return platform;
  }
};

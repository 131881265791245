import { Step as JoyrideStep } from 'react-joyride';

import { Placement, Step } from '../models/Walkthrough';

//Mappings to convert Joyride to Walkthrough
export function JoyrideStepToWalkthroughStep(step: JoyrideStep): Step {
  return {
    target: step.target,
    title: step.title,
    content: step.content,
    placement: step.placement as Placement,
  } as Step;
}

//Mappings to convert Walkthrough to Joyride
export function WalkthroughStepToJoyrideStep(step: Step): JoyrideStep {
  return {
    target: step.target,
    title: step.title,
    content: step.content,
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: step.placement,
    spotlightClicks: false,
  } as JoyrideStep;
}
